
import IamListToolbar from '@/modules/iam/components/iam-list-toolbar.vue';
import IamListFilter from '@/modules/iam/components/iam-list-filter.vue';
import IamListTable from '@/modules/iam/components/iam-list-table.vue';
import { i18n } from '@/i18n';

export default {
  name: 'app-iam-list-page',

  components: {
    [IamListToolbar.name]: IamListToolbar,
    [IamListFilter.name]: IamListFilter,
    [IamListTable.name]: IamListTable,
  },

  data() {
    return {
      tab: 'admins',
    }
  },

  methods: {
    i18n(key, args) {
      return i18n(key, args)
    },
  },
};
