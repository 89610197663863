
import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import Roles from '@/security/roles';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

const { fields } = UserModel;

export default {
  name: 'app-iam-list-table',
  props: ['userType'],

  mounted() {
    if (this.userType == 'client') {
      this.columns.splice(4, 1)
    }
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      language: getLanguageCode(),
      selected: [],
      Pagination: {
        rowsPerPage: 10
      },
      columns: [
        // {
        //   name: 'id',
        //   field: 'id',
        //   label: '#',
        //   align: 'center',
        //   format: (val) => `${val}`,
        //   required: true,
        //   // sortable: true,
        // },
        {
          name: 'avatarsIam',
          field: 'avatarsIam',
          label: i18n('user.fields.avatars'), 
          align: 'center',
          // required: true,
        },
        {
          name: 'fullName',
          field: 'fullName',
          label: i18n('user.fields.fullName'),
          align: 'center',
          // sortable: true,
        },
        {
          name: 'email',
          field: 'email',
          label: i18n('user.fields.email'), 
          align: 'center',
          // format: (val) => `${val}`,
          // required: true,
          // sortable: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: i18n('user.fields.phoneNumber'),
          align: 'center',
          // sortable: true,
        },        
        {
          name: 'roles',
          field: 'roles',
          label: i18n('user.fields.roles'),
          align: 'center',
        },
        {
          name: 'status',
          field: 'disabledAsStatus',
          label: i18n('user.fields.status'),
          align: 'center',
          // sortable: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: i18n('user.fields.createdAt'),
          align: 'center',
          sortable: true,
        },
        {
          name: 'time',
          field: 'createdAt',
          label: i18n('common.time'),
          align: 'center',
          // required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label:  i18n('common.action'),
          align: 'center',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      rows: 'iam/list/rows',
      count: 'iam/list/count',
      loading: 'iam/list/loading',
      pagination: 'iam/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    hasPermissionToEdit() {
      return new IamPermissions(this.currentUser).edit;
    },

    fields() {
      return fields;
    },
    isAdminsTab() {
      return this.userType == 'admin'
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'iam/list/doChangeSort',
      doChangePaginationCurrentPage: 'iam/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'iam/list/doChangePaginationPageSize',
      doMountTable: 'iam/list/doMountTable',
    }),
    i18n(key, args) {
      return i18n(key, args);
    }, 
    presenter(row, fieldName) {
      const val = UserModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    // presenterBoolean(row, fieldName) {
    //   const val = UserModel.presenter(row, fieldName);
    //   return val ? this.fields[fieldName].yesLabel : this.fields[fieldName].NoLabel;
    // },
    presenterMap(row, fieldName) {
      const val = UserModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = UserModel.presenter(row, fieldName);
      return val ? moment(val).locale(this.language).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = UserModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(UserModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = UserModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.language).format('hh:mm a')
    },
    // presenterUser(row, fieldName, key) {
    //   const user = UserModel.presenter(row, fieldName);
    //   if (key == 'avatars' || key == 'avatar') {
    //     let avatar = null
    //     if (user && Array.isArray(user[key])) {
    //       avatar = user[key].length ? user[key][0].publicUrl : null
    //     } else if (user && user[key]) {
    //       avatar = user[key].publicUrl 
    //     }
    //     return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
    //   }
    //   if (key == 'userType') {
    //     return this.presenterEnum(user, key)
    //   }
    //   if (key == 'disabled') {
    //     return user[key] ? this.fields[key].yesLabel : this.fields[key].noLabel;
    //   }
    //   return user && user[key] ? user[key] : 'ـــ'; 
    // },

    roleDescriptionOf(roleId) {
      return Roles.descriptionOf(roleId);
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
  },
};
